import { Col, Container, Row } from "react-bootstrap";
import "./index.scss";

const Home = (): JSX.Element => {
  return (
      <div className="home">
        <div className="main">
          <div className="header">
            <Container>
                <div className="header-con">
                  <div className="logo">
                    <img src={require('../../assets/image/logo.png').default} alt="" height='100%'/>
                  </div>
                  <div className="arrow">
                    <img src={require('../../assets/image/arrow.png').default} alt="" height='100%'/>
                  </div>
                </div>
            </Container>
          </div>
          <div className="body">
              <Container>
                <Row className="pt-xl-2 pt-md-5 pt-sm-5 pt-4 pb-md-1 px-md-2 img-row" >
                  <Col xs={12} md={0} lg={0} xl={7} className=' d-flex flex-column justify-content-center px-md-0'>
                      <h1 className="title text-center text-md-center text-lg-center text-xxl-start">
                      YOUR VERIFIED <span><br/></span>NFT CAMERA
                      </h1>
                      <h2 className="subtitle text-center text-md-center text-lg-center text-xxl-start">Have Fun in the<span><br/></span> Meetverse</h2>
                  </Col>
                  <Col xs={12} md={12} lg={12} xl={5} className='px-md-0 px-0'>
                    <div className="py-md-4 px-lg-0 py-lg-0 px-0 py-0 pb-3 photo-big">
                      <img src={require('../../assets/image/jpg_bg_1.png').default} alt="" width='100%'/>
                    </div>
                  </Col>
                </Row>
              </Container>
              <div className="photo-small">
                <img src={require('../../assets/image/jpg_bg_2.png').default} alt="" width='100%'/>
              </div>
            </div>
        </div>
        <div className="footer">
          <div className="footer-bg"></div>
            <Container>
          <div className="footer-con">

                  <ul className="leftFooter pb-2 pb-sm-0 text-md-start text-sm-start text-center">
                    <li><a href="/service" target='_blank' rel="noreferrer">Terms of Service</a></li>
                    <li><a href="/privacy" target='_blank' rel="noreferrer">Privacy Policy</a></li>
                    <li><span>Copyright   2022 REAL</span></li>
                  </ul>
               
                  <ul className="socialList d-flex justify-content-end align-items-center pt-2 pb-2 pt-sm-0 pb-sm-0">
                    <li>
                      <a href="https://twitter.com/realapp_xyz" target='_blank' rel="noreferrer">
                        <img src={require('../../assets/image/twitter.png').default} alt="" height='100%'/>
                      </a>
                    </li>
                  
                    <li>
                      <a href="https://www.tiktok.com/@realapp_xyz" target='_blank' rel="noreferrer">
                        <img src={require('../../assets/image/tiktok.png').default} alt="" height='100%'/>
                      </a>
                    </li>
                    <li>
                      <a href="https://discord.gg/etfrb9x37t" target='_blank' rel="noreferrer">
                        <img src={require('../../assets/image/discord.png').default} alt="" height='100%'/>
                      </a>
                    </li>
                    <li>
                      <a href="mailto:hello@realapp.xyz" target='_blank' rel="noreferrer">
                        <img src={require('../../assets/image/email.png').default} alt="" height='100%'/>
                      </a>
                    </li>
                    <li>
                      <a href=" https://medium.com/@realapp_xyz" target='_blank' rel="noreferrer">
                        <img src={require('../../assets/image/medium.png').default} alt="" height='100%'/>
                      </a>
                    </li>
                  </ul>
                </div>
            </Container>
        </div>
      </div>
  );
}

export default Home;