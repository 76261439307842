import './index.scss'

const TermsOfService = (): JSX.Element => {
  return (
    <>
      <div className="service">
        <div className="container-fluid container">
          <h1 className="title">
          Terms of Service
          </h1>
          <p className='date'>Effective: 1 May 2022</p>
          <p>
          Welcome to REAL Cam!
          </p>
          <p>
          We appreciate your choice of using our camera application.
          </p>
     
     
          <h2 className="subtitle">Please read carefully these Terms of Use (“Terms”) before using</h2>
          <p>
          the services offered by REAL Cam. These Terms constitute a legally binding agreement between REAL Cam (also “us”) and User (also “you”, “your”), which regulates your use of  the REAL Cam mobile application (“Application”) and other services as provided herein.
          </p>
          <p>
          By downloading from the Apple App Store or other platform, installing the application, or ticking the respective box in the form on the application, you agree to be bound by these Terms and REAL Privacy Policy (“Privacy Policy”). Your downloading of the application from the Apple App Store or other platform is also governed by the respective terms of use and other accompanying documents of Apple App Store or other platforms.
          </p>
          <p>
          If Reface provides you with the Services through the website, you agree to be bound by these Terms and the Privacy Policy by using the website.
          </p>
          <p>
          If you do not agree to these Terms, do not access or use our Services.
          </p>
          <h2 className="subtitle">1. Who Can Use the Services</h2>
          <p>
          No one under 4 is allowed to use the Services. If you are under 14, you may only use the Services with the prior consent of your parent or legal guardian. Please be sure your parent or legal guardian has reviewed and discussed these Terms with you before you start using the Services. We may offer additional Services with additional terms that may require you to be even older to use them. So please read all terms carefully. By using the Services, you represent, warrant, and agree that:
          </p>
          <p>
          If you are using the Services on behalf of a business or some other entity, you represent that you are authorized to bind that business or entity to these Terms and you agree to these Terms on behalf of that business or entity (and all references to “you” and “your” in these Terms will mean both you as the end-user and that business or entity). 
          </p>
          <h2 className="subtitle">2. Rights We Grant You</h2>
          <p>
          As between you and us, REAL (and its licensors) is the owner of the Services, including all proprietary content, information, material, software, images, text, graphics, illustrations, logos, patents, trademarks, service marks, copyrights, photographs, audio, video and music the Services, and all related intellectual property rights. REAL grants you a worldwide, royalty-free, non-assignable, non-exclusive, revocable, and non-sublicensable license to use the Services. This license is for the sole purpose of using and enjoying the Services in a way that these Terms and our policies allow. You may not use the Services in ways that are not authorized by these Terms. Nor may you help anyone else in doing so.
          </p>
          <h2 className="subtitle">3. Rights You Grant Us</h2>
          <p>
          We do not claim any ownership rights in the Content that you post on or through the Service.
          </p>
          <p>
          For all content you submit to the Services, you grant REAL and our affiliates a worldwide, royalty-free, sublicensable, and transferable license to host, store, cache, use, display, reproduce, modify, adapt, edit, publish, analyze, transmit, and distribute that content. This license is for the purpose of operating, developing, providing, promoting, and improving the Services and researching and developing new ones. This license includes a right for us to make your content available to, and pass these rights along to, service providers with whom we have contractual relationships related to the provision of the Services, solely for the purpose of providing such Services. 
          </p>
          <p>
          We call Story submissions that are set to be viewable by Everyone as well as content you submit to public Services. Because Public Content is inherently public, you grant REAL, our affiliates, other users of the Services, and our business partners all of the same rights you grant for non-Public Content in the previous paragraph, as well as an unrestricted, worldwide, royalty-free, irrevocable, and perpetual right and license to create derivative works from, promote, exhibit, broadcast, syndicate, reproduce, distribute, synchronize, overlay graphics and auditory effects on, publicly perform, and publicly display all or any portion of your Public Content in any form and in any and all media or distribution methods, now known or later developed. When you appear in, create, upload, post, or send Public Content, you also grant REAL, our affiliates, other users of the Services, and our business partners an unrestricted, worldwide, royalty-free, irrevocable, and perpetual right and license to use the name, likeness, and voice, of anyone featured in your Public Content for commercial and non-commercial purposes. This means, among other things, that you will not be entitled to any compensation if your content, videos, photos, likeness, or voice are used by us, our affiliates, users of the Services, or our business partners. For information about how to tailor who can watch your content, please take a look at our Privacy Policy. All Public Content must be appropriate for people ages 13+.
          </p>
          <p>
          We, our affiliates, and our third-party partners may place advertising on the Services, including personalized advertising based on the information you provide us, we collect, or we obtain about you. Advertising may sometimes appear near, between, over, or in your content.
          </p>
          <p>
          We always love to hear from our users. But if you provide feedback or suggestions, just know that we can use them without compensating you, and without any restriction or obligation to you. You agree that we will own all rights in any materials or items we develop based on such feedback or suggestions.
          </p>
          <h2 className="subtitle">4. Additional Terms for Specific Services</h2>
          <p>
          Additional terms and conditions that are otherwise made available to you may apply to specific Services. If you use those Services, then those additional terms become part of these Terms. If any of the applicable additional terms conflict with these Terms, the additional terms will prevail while you are using the Services to which they apply.
          </p>
         
          <h2 className="subtitle">5. Privacy</h2>
          <p>
          Your privacy matters to us. You can learn how your information is handled when you use our Services by reading our Privacy Policy.
          </p>
          <h2 className="subtitle">6. The Content of Others</h2>
          <p>
          Much of the content on our Services is produced by users, publishers, and other third parties. Whether that content is posted publicly or sent privately, the content is the sole responsibility of the user or entity that submitted it. Although REAL reserves the right to review or remove all content that appears on the Services, we do not necessarily review all of it. So we cannot—and do not—guarantee that other users or the content they provide through the Services will comply with our Terms or Privacy Policy.
          </p>
          <h2 className="subtitle">7. Respecting the Services and REAL’s Rights</h2>
          <p>
          You must also respect REAL’s rights and adhere to REAL Brand Guidelines, support pages, or FAQs published by REAL or our affiliates. That means, among other things, you may not do, attempt to do, enable, or encourage anyone else to do, any of the following:
          </p>
          <p>
          - use branding, logos, icons, user interface elements, designs, photographs, videos, or any other materials REAL makes available via the Services, except as explicitly allowed by these Terms, the REAL brand guidelines published by REAL or our affiliates;
          </p>
          <p>
          - violate or infringe REAL’s or our affiliates’ copyrights, trademarks, or other intellectual property rights;
          </p>
          <p>
          - copy, modify, archive, download, upload, disclose, distribute, sell, lease, syndicate, broadcast, perform, display, make available, make derivatives of, or otherwise use the Services or the content on the Services, other than temporary files that are automatically cached by your web browser for display purposes, as otherwise expressly permitted in these Terms, as otherwise expressly permitted by us in writing, or as enabled by the Service’s intended functionality;
          </p>
          <p>
          - create more than one account for yourself, create another account if we have already disabled your account, attempt to access the Services through unauthorized third-party applications, solicit login credentials from other users, or buy, sell, rent, or lease access to your account, a username, REAL, or a friend link;
          </p>
          <p>
          - reverse engineer, duplicate, decompile, disassemble, or decode the Services (including any underlying idea or algorithm), or otherwise extract the source code of the software of the Service;
          </p>
          <p>
          - use any robot, spider, crawler, scraper, or other automated means or interface to access the Services or extract other users’ information;
          </p>
          <p>
          - use or develop any third-party applications that interact with the Services or other users’ content or information without our written consent;
          </p>
          <p>
          - use the Services in a way that could interfere with, disrupt, negatively affect, or inhibit other users from fully enjoying the Services, or that could damage, disable, overburden, or impair the functioning of the Services;
          </p>
          <p>
          - upload viruses or other malicious code or otherwise compromise, bypass, or circumvent the security of the Services;
          </p>
          <p>
          - attempt to circumvent any content-filtering techniques we employ or attempt to access areas or features of the Services that you are not authorized to access;
          </p>
          <p>
          - probe, scan, or test the vulnerability of our Services or any system or network;
          </p>
          <p>
          - violate any applicable law or regulation in connection with your access to or use of the Services; or
          </p>
          <p>
          - access or use the Services in any way not expressly permitted by these Terms.
          </p>
          <div className="subtitle">8. Respecting Others' Rights</div>
          <p>
          REAL respects the rights of others. And so should you. You therefore may not use the Services or enable anyone else to use the Services, in a manner that violates or infringes someone else’s rights of publicity, privacy, copyright, trademark, or other intellectual property rights. When you submit content to the Service, you agree and represent that you own that content, or that you have received all necessary permissions, clearances, and authorizations in order to submit it to the Service (including, if applicable, the right to make mechanical reproductions of the musical works embodied in any sound recordings, synchronize any compositions to any content, publicly perform any compositions or sound recordings or any other applicable rights for any music not provided by REAL that you include in your content) and grant the rights and licenses contained in these Terms for your content. You also agree that you will not use or attempt to use another user’s account except as permitted by REAL or its affiliates.
          </p>
          <p>
          REAL honors copyright laws, including the Digital Millennium Copyright Act and takes reasonable steps to expeditiously remove from our Services any infringing material that we become aware of. If REAL becomes aware that a user has repeatedly infringed copyrights, we will take reasonable steps within our power to terminate the user’s account. If you believe that anything on the Services infringes a copyright that you own or control, please report it using the form accessible through REAL support. Or you may file a notice with our designated agent: email: feedback@realapp.xyz. If you file a notice with our Copyright Agent, it must comply with the requirements set forth at 17 U.S.C. § 512(c)(3). That means the notice must:
          </p>
          <p>
          - contain the physical or electronic signature of a person authorized to act on behalf of the copyright owner;
          </p>
          <p>
          - identify the copyrighted work claimed to have been infringed;
          </p>
          <p>
          - identify the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed, or access to which is to be disabled, and information reasonably sufficient to let us locate the material;
          </p>
          <p>
          - provide your contact information, including your address, telephone number, and an email address;
          </p>
          <p>
          - provide a personal statement that you have a good-faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and
          </p>
          <p>
          - provide a statement that the information in the notification is accurate and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.
          </p>
          <h2 className="subtitle">9. Safety</h2>
          <p>
          We try hard to keep our Services a safe place for all users. But we can’t guarantee it. That’s where you come in. By using the Services, you agree that you will at all times comply with these Terms, including our community guidelines and any other policies REAL makes available in order to maintain the safety of the Services. If you fail to comply, we reserve the right to remove any offending content, terminate or limit the visibility of your account, and notify third parties—including law enforcement—and provide those third parties with information relating to your account. This step may be necessary to protect the safety of our users, and others, to investigate, remedy, and enforce potential Terms violations, and to detect and resolve any fraud or security concerns.
          </p>
          <p>
          We also care about your physical safety while using our Services. So do not use our Services in a way that would distract you from obeying traffic or safety laws. For example, never use the Services while driving. And never put yourself or others in harm’s way just to use REAL.
          </p>
          <h2 className='subtitle'>10. Third-Party Services</h2>
          <p>
          Certain Services may display, include or make available content, data, information, applications, features, or materials from third parties (“Third-Party Materials”), or provide links to certain third-party websites. If you use any Third-Party Materials made available through our Services (including Services we jointly offer with the third party), each party’s terms will govern the respective party’s relationship with you. Neither REAL nor our affiliates are responsible or liable for a third party’s terms or actions taken under the third party’s terms. Further, by using the Services, you acknowledge and agree that REAL is not responsible for examining or evaluating the content, accuracy, completeness, availability, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect of such Third-Party Materials or websites. We do not warrant or endorse and do not assume and will not have any liability or responsibility to you or any other person for any third-party services, Third-Party Materials or third-party websites, or for any other materials, products, or services of third parties. Third-Party Materials and links to other websites are provided solely as a convenience to you.
          </p>
          <h2 className='subtitle'>11. Modifying the Services and Termination</h2>
          <p>
          We’re relentlessly improving our Services and creating new ones all the time. That means we may add or remove features, products, or functionalities, and we may also suspend or stop the Services altogether. We may take any of these actions at any time for any reason, and when we do, we may not provide you with any notice beforehand.
          </p>
          <p>
          While we hope you remain a lifelong REAL user, you can terminate these Terms at any time and for any reason by deleting your REAL account (or, in some cases, the account associated with the applicable part of the Services you are using).
          </p>
          <p>
          We may terminate or temporarily suspend your access to the Services if you fail to comply with these Terms, our community guidelines or the law, for any reason outside of our control, or for any reason, and without advanced notice. That means that we may terminate these Terms, stop providing you with all or any part of the Services, or impose new or additional limits on your ability to use our Services. And while we’ll try to give you reasonable notice beforehand, we can’t guarantee that notice will be possible in all circumstances. For example, we may deactivate your account due to prolonged inactivity, and we may reclaim your username at any time for any reason.
          </p>
          <p>
          Regardless of who terminates these Terms, both you and REAL continue to be bound by Sections 3, 4 (to the extent any additional terms and conditions would, by their terms, survive), and 6 - 23 of the Terms.
          </p>
          <h2 className="subtitle">12. Indemnity</h2>
          <p>
          You agree, to the extent permitted by law, to indemnify, defend, and hold harmless REAL, our affiliates, directors, officers, stockholders, employees, licensors, and agents from and against any and all complaints, charges, claims, damages, losses, costs, liabilities, and expenses (including attorneys’ fees) due to, arising out of, or relating in any way to: (a) your access to or use of the Services, or any products or services provided by a third party in connection with the Services, even if recommended, made available, or approved by REAL; (b) your content, including infringement claims related to your content; (c) your breach of these Terms or any applicable law or regulation; or (d) your negligence or willful misconduct.
          </p>
          <h2 className="subtitle">13. Disclaimers</h2>
          <p>We try to keep the Services up and running and free of annoyances. But we make no promises that we will succeed.</p>
          <p>
          THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” AND TO THE EXTENT PERMITTED BY LAW WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. IN ADDITION, WHILE WE ATTEMPT TO PROVIDE A GOOD USER EXPERIENCE, WE DO NOT REPRESENT OR WARRANT THAT: (A) THE SERVICES WILL ALWAYS BE SECURE, ERROR-FREE, OR TIMELY; (B) THE SERVICES WILL ALWAYS FUNCTION WITHOUT DELAYS, DISRUPTIONS, OR IMPERFECTIONS; OR (C) THAT ANY CONTENT, USER CONTENT, OR INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICES WILL BE TIMELY OR ACCURATE. NEITHER WE NOR OUR AFFILIATES TAKE RESPONSIBILITY OR ASSUME LIABILITY FOR ANY CONTENT THAT YOU, ANOTHER USER, OR A THIRD PARTY CREATES, UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON OR THROUGH OUR SERVICES. YOU UNDERSTAND AND AGREE THAT YOU MAY BE EXPOSED TO CONTENT THAT MIGHT BE OFFENSIVE, ILLEGAL, MISLEADING, OR OTHERWISE INAPPROPRIATE, NONE OF WHICH WE OR OUR AFFILIATES WILL BE RESPONSIBLE FOR.
          </p>
          <p>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE AND OUR MANAGING MEMBERS, SHAREHOLDERS, EMPLOYEES, AFFILIATES, LICENSORS, AGENTS, AND SUPPLIERS WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR MULTIPLE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (B) THE CONDUCT OR CONTENT OF OTHER USERS OR THIRD PARTIES ON OR THROUGH THE SERVICES; OR (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL OUR AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES EXCEED THE GREATER OF $100 USD OR THE AMOUNT YOU PAID US IN THE 12 MONTHS PRECEDING THE DATE OF THE ACTIVITY GIVING RISE TO THE CLAIM.
          </p>

          <h2 className="subtitle">14. Exclusive Venue</h2>
          <p>
          To the extent that these Terms allow you or REAL: to initiate litigation in a court, both you and REAL agree that all claims and disputes (whether contract, tort, or otherwise), including statutory claims and disputes, arising out of or relating to the Terms or the use of the Services will be litigated exclusively in the United States District Court for the Central District of California. If, however, that court would lack original jurisdiction over the litigation, then all such claims and disputes will be litigated exclusively in the Superior Court of California, County of Los Angeles. You and REAL consent to the personal jurisdiction of both courts.
          </p>
          <h2 className="subtitle">15. Choice of Law</h2>
          <p>
          Except to the extent they are preempted by U.S. federal law, the laws of California, other than its conflict-of-laws principles, govern these Terms and any claims and disputes (whether contract, tort, or otherwise) arising out of or relating to these Terms or their subject matter.
          </p>
          <h2 className="subtitle">16. Severability</h2>
          <p>
          If any provision of these Terms is found unenforceable, then that provision will be severed from these Terms and not affect the validity and enforceability of any remaining provisions.
          </p>
          <h2 className="subtitle">17. Final Terms</h2>
          <p>
          These Terms, including the additional terms referenced in Section 4, make up the entire agreement between you and REAL, and supersede any prior agreements. These Terms do not create or confer any third-party beneficiary rights. If we do not enforce a provision in these Terms, it will not be considered a waiver. We reserve the right to transfer our rights under these Terms and provide the Services using another entity, provided that entity upholds these Terms. You may not transfer any of your rights or obligations under these Terms without our consent. We reserve all rights not expressly granted to you.
          </p>
          <h2 className='subtitle'>Contact Us</h2>
          <p>
          REAL welcomes comments, questions, concerns, or suggestions. Please contact us by visiting  <a href="https://www.realapp.xyz/">https://www.realapp.xyz/</a>.
          </p>
        </div>
      </div>
    </>
  );
}

export default TermsOfService;